.benefits__container {
  @extend %flex-dc;
  gap: 16px;
}

.benefits__item {
  @extend %relative;
  display: flex;
  gap: 4px;

  &::before {
    content: '-';
  }
}
