.veka__container {
  @extend %flex-dc;
  gap: 16px;
}

.veka__img {
  width: 100%;
  // max-width: 600px;
  height: auto;
  margin: 0 auto;

  border-radius: var(--border-radius);
}
.veka__logo {
  max-width: 200px;
}


.veka__text--indent {
  text-indent: var(--text-indent);
}
.veka__text--center {
  text-align: center;
}
.veka__text--800 {
  font-weight: 800;
}

.veka-products {
  margin: 20px 0;
}
.veka-products__list {
  @extend %cardSet;
  // justify-content: flex-start;
  --gap: 28px;
}
.veka-product {
  @extend %cardSet__item;
  @extend %flex-dc;
  gap: 24px;
  height: 100%;

  @include breakpoint-min($tablet) {
    --items: 2;
  }
  @include breakpoint-min($laptop) {
    --items: 3;
  }
}
.veka-product__img {
  flex-grow: 1;
  object-fit: contain;
  min-height: 280px;
  max-height: 280px;
  height: auto;
  margin-bottom: auto;
  
  background-color: #fff;
  @extend %border-radius;
}
.veka-product__options-list {
  @extend %flex-dc;
  gap: 2px;
  flex-grow: 1;
}
.veka-product__options-item .square {
  @extend %relative;
  top: -6px;
  font-size: 8px;
  font-weight: 600;
}

.veka-product__title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}