.hero__container {
  @extend %flex-dc;
  gap: 16px;
}

.hero__img {
  width: 100%;
  // max-width: 600px;
  height: auto;
  margin: 0 auto;

  border-radius: var(--border-radius);
}


.hero__text--indent {
  text-indent: var(--text-indent);
}