.modal-offers__btn-open {
  @extend %flex-ac-jc;
  height: 48px;
  width: 240px;
  padding: 0 12px;
  margin: 0 auto;

  color: var(--color--light, #f0f0f0);
  font-weight: 600;
  text-transform: uppercase;

  background-color: var(--color--red, #990000);
  border-radius: calc(var(--border-radius) * 2);
  border: 1px solid var(--color--accent, #26354a);
  @extend %box-shadow-active;
  @extend %transition--200;

  &:hover {
    color: var(--color--red, #990000);
    background-color: var(--color--gray, #e0e7ef);
  }
}
