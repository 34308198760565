.review__link {
  @extend %flex-ac-jc;
}

.review__img {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.reviews-swiper {
  padding-bottom: 40px;
}

.reviews-swiper__btn {
  @extend %absolute;
  right: 0;

  @extend %flex-ac-jc;
  width: 32px;
  height: 32px;
  bottom: 0;
  z-index: 2;

  background-color: var( --color--accent, #26354a);

  &:hover {
    opacity: 0.8;
  }
}
.reviews-swiper-btn-prev {
  right: 40px;
}

.reviews-swiper-pagination {
  @extend %absolute;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;

  @extend %transition--200;
  opacity: 0.5; 
}
.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;

  opacity: 1;

  background-color: var( --color--accent, #26354a);
}