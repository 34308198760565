// common styles
@import '/src/common/scss/var.scss';
@import '/src/common/scss/utils.scss';
// components styles
@import '/src/components/modal/modal-offers/modal-offers.scss';
@import '/src/components/section/hero/hero.scss';
@import '/src/components/section/features/features.scss';
@import '/src/components/section/veka/veka.scss';
@import '/src/components/section/partners/partners.scss';
@import '/src/components/section/benefits/benefits.scss';
@import '/src/components/section/reviews/reviews.scss';