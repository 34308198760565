.features__list {
  @extend %flex-dc;
  gap: 24px;
}

.feature {
  @extend %flex-dc;
  gap: 4px;

  @include breakpoint-min($tablet) {
    flex-direction: row;
    gap: 20px
  }
}

.feature__title {
  @include breakpoint-min($tablet) {
    flex: 0 0 220px;
  }
}

.feature__icon {
  flex: 0 0 60px;
  @extend %border-radius;
  border: 4px solid var(--color--accent, #26354a);

  @include breakpoint-min($laptop) {
    margin-right: 20px;
  }
}