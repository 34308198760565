:root {
  // ===== fonts =====
  // --font--title: 'Montserrat', sans-serif;
  // --font--body: 'Montserrat', sans-serif;
  // ===== colors =====
  --color--light: #f0f0f0;
  // --color--light: #f0f0fa;
  --color--dark: #000000;
  --color--accent: #26354a;
  --color--gray: #e0e7ef;
  --color--red: #990000;

  // ===== transition =====
  --transition--100: 100ms linear;
  --transition--200: 200ms linear;
  --transition--300: 300ms linear;
  --transition--400: 400ms linear;
  --transition--500: 500ms linear;
  // ===== decor =====
  --box-shadow: 0 2px 2px rgba(0, 0, 0, 0.22);
  --box-shadow--main: 0 0.5px 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(90, 100, 109, 0.2);
  --box-shadow--active: 0 0 8px -2px rgba(0, 0, 0, 0.1), 0 6px 20px -3px rgba(0, 0, 0, 0.2);
  // ===== other =====
  --items: 1;
  --gap: 16px;
  --index: calc(1vh + 1vw);
  --border-radius: 8px;
  --text-indent: 40px;
}

// ===== breakpoints =====
$mobileS: 320px;
$mobileM: 375px;
$mobileL: 425px;
$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;
$desktopL: 1440px;
// ===== breakpoints =====
