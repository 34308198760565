:root {
  --color--light: #f0f0f0;
  --color--dark: #000;
  --color--accent: #26354a;
  --color--gray: #e0e7ef;
  --color--red: #900;
  --transition--100: .1s linear;
  --transition--200: .2s linear;
  --transition--300: .3s linear;
  --transition--400: .4s linear;
  --transition--500: .5s linear;
  --box-shadow: 0 2px 2px #00000038;
  --box-shadow--main: 0 .5px 1px #0000001a, 0 2px 6px #5a646d33;
  --box-shadow--active: 0 0 8px -2px #0000001a, 0 6px 20px -3px #0003;
  --items: 1;
  --gap: 16px;
  --index: calc(1vh + 1vw);
  --border-radius: 8px;
  --text-indent: 40px;
}

.reviews-swiper__btn, .review__link, .partners-swiper__btn, .partner__link, .offer__value, .offer__empty, .offer__name, .modal-offers__btn-open {
  justify-content: center;
  align-items: center;
  display: flex;
}

.benefits__container, .veka-product__options-list, .veka-product, .veka__container, .feature, .features__list, .hero__container, .offer__name-wrap, .offer__desc-wrap, .offer__link2, .offers__list, .modal-offers__main {
  flex-direction: column;
  display: flex;
}

.veka-products__list {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.benefits__item, .veka-product__options-item .square, .offer__name {
  position: relative;
}

.reviews-swiper-pagination, .reviews-swiper__btn, .partners-swiper-pagination, .partners-swiper__btn, .offer__icon, .visually-hidden {
  position: absolute;
}

.veka-product__img, .feature__icon {
  border-radius: var(--border-radius);
}

.offer__link2:hover, .offer__link:hover, .modal-offers__btn-open {
  box-shadow: var(--box-shadow--active);
}

.offer__link2 {
  transition: var(--transition--100);
}

.swiper-pagination-bullet, .modal-offers__btn--close, .modal-offers__btn-open {
  transition: var(--transition--200);
}

.visually-hidden {
  overflow: hidden;
}

.veka-products__list {
  gap: var(--gap);
}

.veka-product {
  flex-basis: calc((100% - var(--gap) * (var(--items)  - 1)) / var(--items));
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
}

.modal-offers__btn-open {
  height: 48px;
  width: 240px;
  color: var(--color--light, #f0f0f0);
  text-transform: uppercase;
  background-color: var(--color--red, #900);
  border-radius: calc(var(--border-radius) * 2);
  border: 1px solid var(--color--accent, #26354a);
  margin: 0 auto;
  padding: 0 12px;
  font-weight: 600;
}

.modal-offers__btn-open:hover {
  color: var(--color--red, #900);
  background-color: var(--color--gray, #e0e7ef);
}

.modal-offers__header {
  padding-bottom: 0;
}

.modal-offers__main {
  gap: 20px;
  padding-top: 0;
}

.modal-offers__btn--close:hover {
  scale: 1.2;
}

.modal-offers__title {
  color: var(--color--red, #900);
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
}

.offers__list {
  gap: 40px;
}

.offer__title {
  text-transform: uppercase;
  font-size: 20px;
  font-style: italic;
}

.offer__img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.offer__link:hover {
  opacity: .8;
}

.offer__link2 {
  color: var(--color--light, #f0f0f0);
  background-color: var(--color--red, #900);
  padding: 8px;
}

.offer__link2 span {
  border: 2px solid var(--color--light, #f0f0f0);
  padding: 8px;
}

.offer__link2:hover {
  opacity: .8;
}

@media (min-width: 768px) {
  .offer__name-wrap, .offer__desc-wrap {
    flex-direction: row;
  }
}

.offer__name-wrap {
  text-transform: uppercase;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
}

@media (min-width: 768px) {
  .offer__name-wrap {
    font-size: 28px;
  }
}

.offer__name {
  text-align: center;
  flex-grow: 1;
}

.offer__value {
  font-size: 36px;
}

@media (min-width: 768px) {
  .offer__value, .offer__empty {
    flex: 0 0 160px;
  }
}

.offer__desc {
  text-align: center;
  flex-grow: 1;
}

.modal-offers__info {
  color: var(--color--red, #900);
  font-style: italic;
}

.offer__icon {
  width: 45px;
  height: 45px;
  display: block;
  top: -20px;
  right: -24px;
}

@media (min-width: 768px) {
  .offer__icon {
    top: calc(100% - 20px);
  }
}

.hero__container {
  gap: 16px;
}

.hero__img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  margin: 0 auto;
}

.hero__text--indent {
  text-indent: var(--text-indent);
}

.features__list {
  gap: 24px;
}

.feature {
  gap: 4px;
}

@media (min-width: 768px) {
  .feature {
    flex-direction: row;
    gap: 20px;
  }

  .feature__title {
    flex: 0 0 220px;
  }
}

.feature__icon {
  border: 4px solid var(--color--accent, #26354a);
  flex: 0 0 60px;
}

@media (min-width: 1024px) {
  .feature__icon {
    margin-right: 20px;
  }
}

.veka__container {
  gap: 16px;
}

.veka__img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  margin: 0 auto;
}

.veka__logo {
  max-width: 200px;
}

.veka__text--indent {
  text-indent: var(--text-indent);
}

.veka__text--center {
  text-align: center;
}

.veka__text--800 {
  font-weight: 800;
}

.veka-products {
  margin: 20px 0;
}

.veka-products__list {
  --gap: 28px;
}

.veka-product {
  height: 100%;
  gap: 24px;
}

@media (min-width: 768px) {
  .veka-product {
    --items: 2;
  }
}

@media (min-width: 1024px) {
  .veka-product {
    --items: 3;
  }
}

.veka-product__img {
  object-fit: contain;
  min-height: 280px;
  max-height: 280px;
  height: auto;
  background-color: #fff;
  flex-grow: 1;
  margin-bottom: auto;
}

.veka-product__options-list {
  flex-grow: 1;
  gap: 2px;
}

.veka-product__options-item .square {
  font-size: 8px;
  font-weight: 600;
  top: -6px;
}

.veka-product__title {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.partner__img {
  object-fit: contain;
  max-height: 100px;
}

.partners-swiper {
  padding-bottom: 40px;
}

.partners-swiper__btn {
  width: 32px;
  height: 32px;
  z-index: 2;
  background-color: var(--color--accent, #26354a);
  bottom: 0;
  right: 0;
}

.partners-swiper__btn:hover {
  opacity: .8;
}

.partners-swiper-btn-prev {
  right: 40px;
}

.partners-swiper-pagination {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  opacity: .5;
}

.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: var(--color--accent, #26354a);
}

.benefits__container {
  gap: 16px;
}

.benefits__item {
  gap: 4px;
  display: flex;
}

.benefits__item:before {
  content: "-";
}

.review__img {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.reviews-swiper {
  padding-bottom: 40px;
}

.reviews-swiper__btn {
  width: 32px;
  height: 32px;
  z-index: 2;
  background-color: var(--color--accent, #26354a);
  bottom: 0;
  right: 0;
}

.reviews-swiper__btn:hover {
  opacity: .8;
}

.reviews-swiper-btn-prev {
  right: 40px;
}

.reviews-swiper-pagination {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  opacity: .5;
}

.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: var(--color--accent, #26354a);
}

/*# sourceMappingURL=index.a71b4302.css.map */
