@import '/src/components/modal/modal-offers/modal-offers-btn-open.scss';

.modal-offers__header {
  padding-bottom: 0;
}
.modal-offers__main {
  @extend %flex-dc;
  gap: 20px;
  padding-top: 0;
}

.modal-offers__btn--close {
  @extend %transition--200;

  &:hover {
    scale: (1.2);
  }
}

.modal-offers__title {
  color: var(--color--red, #990000);
  font-size: 32px;
  text-transform: uppercase;
  text-align: center;
}

.offers__list {
  @extend %flex-dc;
  gap: 40px;
}

.offer__title {
  font-size: 20px;
  font-style: italic;
  text-transform: uppercase;
}
.offer__img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.offer__link {
  &:hover {
    @extend %box-shadow-active;
    opacity: 0.8;
  }
}

.offer__link2 {
  @extend %flex-dc;
  padding: 8px;

  color: var(--color--light, #f0f0f0);
  background-color: var(--color--red, #990000);
  @extend %transition--100;

  & span {
    padding: 8px;

    border: 2px solid var(--color--light, #f0f0f0);
  }

  &:hover {
    @extend %box-shadow-active;
    opacity: 0.8;
    // scale: (1.01)
  }
}

.offer__name-wrap,
.offer__desc-wrap {
  @extend %flex-dc;

  @include breakpoint-min($tablet) {
    flex-direction: row;
  }
}
.offer__name-wrap {
  font-size: 24px;
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;

  @include breakpoint-min($tablet) {
    font-size: 28px;
  }
}

.offer__name {
  flex-grow: 1;
  @extend %flex-ac-jc;
  @extend %relative;

  text-align: center;
}
.offer__value {
  font-size: 36px;
}
.offer__value,
.offer__empty {
  @extend %flex-ac-jc;

  @include breakpoint-min($tablet) {
    flex: 0 0 160px;
  }
}

.offer__desc {
  flex-grow: 1;
  text-align: center;
}

.modal-offers__info  {
  font-style: italic;
  color: var(--color--red, #990000);
}

.offer__icon {
  @extend %absolute;
  top: -20px;
  right: -24px;

  display: block;
  width: 45px;
  height: 45px;

  @include breakpoint-min($tablet) {
    top: calc(100% - 20px);
  }
}